import React, { Suspense } from 'react';
import { Loader } from '@alphakits/ui/dist';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Agb } from './components/agb';
import { Certificate } from './components/certificate';
import { Guide } from './components/guide';
import { Order } from './components/order';
import { OrderWaiver } from './components/order-waiver';
import { ProfileAgb } from './components/profile-agb';
import { SucceedPayment } from './components/succeed-payment';
import { ApiProvider } from './providers/api/context';

import './index.css';
import '@alphakits/ui/dist/index.css';

import 'core-js/proposals/reflect-metadata';
import './locales/i18n';

const Container = ({ children }) => (
    <ApiProvider>
        <Suspense fallback={ <Loader /> }>{ children }</Suspense>
    </ApiProvider>
);

export type PaymentStatus = 'failed' | 'succeed';

const StripeWrapper = ({ children, payee }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN, {
        stripeAccount: payee,
    });

    return (
        <Elements
            options={ {
                appearance: {
                    theme: 'stripe',
                },
            } }
            stripe={ stripePromise }
        >
            { children }
        </Elements>
    );
};

export default function App() {
    const urlParams = new URLSearchParams(window.location.search);

    const orderId = urlParams.get('order');
    const waiverId = urlParams.get('waiver');
    const certificateId = urlParams.get('certificate');
    const profileId = urlParams.get('profileId');
    const guideId = urlParams.get('guideId');
    const widget = urlParams.get('widget');
    const country = urlParams.get('country');
    const type = urlParams.get('type');
    const payee = urlParams.get('payee');
    const redirectStatus = urlParams.get('redirect_status') as PaymentStatus;

    if (redirectStatus === 'succeed' && orderId) {
        return (
            <Container>
                <SucceedPayment backLink={ `/?order=${orderId}` } />
            </Container>
        );
    }

    if (redirectStatus === 'succeed' && certificateId) {
        return (
            <Container>
                <SucceedPayment backLink={ `/?certificate=${certificateId}` } />
            </Container>
        );
    }

    if (widget) {
        return <React.Fragment>{ widget }</React.Fragment>;
    }

    if (orderId) {
        return (
            <Container>
                <StripeWrapper payee={ payee }>
                    <Order failedPayment={ redirectStatus === 'failed' } orderToken={ orderId } />
                </StripeWrapper>
            </Container>
        );
    }

    if (guideId) {
        return (
            <Container>
                <Guide guideId={ guideId } />
            </Container>
        );
    }

    if (certificateId) {
        return (
            <Container>
                <StripeWrapper payee={ payee }>
                    <Certificate
                        failedPayment={ redirectStatus === 'failed' }
                        certificateToken={ certificateId }
                    />
                </StripeWrapper>
            </Container>
        );
    }

    if (waiverId) {
        return (
            <Container>
                <OrderWaiver owner={ !!urlParams.get('owner') } orderToken={ waiverId } />
            </Container>
        );
    }

    if (type && country) {
        return (
            <Container>
                <Agb type={ type } country={ country } />
            </Container>
        );
    }

    if (profileId) {
        return (
            <Container>
                <ProfileAgb profileId={ profileId } />
            </Container>
        );
    }

    return null;
}
