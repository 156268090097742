import React from 'react';
import { Booking } from 'src/components/booking';
import { Root } from 'src/root';

import { BOOKING } from './constants';

const routes = () => {
    const res = [];

    res.push({
        path: '/',
        element: <Root />,
    });

    res.push({
        path: BOOKING,
        element: <Booking />,
    });

    return res;
};

export default routes;
