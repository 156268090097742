/* eslint-disable complexity */
import React, { useEffect } from 'react';
import { Loader, ToastPlate } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { useApi } from 'src/providers/api/context';

type Props = {
    type: string;
    country: string;
};

export const Agb: React.FC<Props> = ({ type, country }) => {
    const { agbs } = useApi();

    const {
        getForOrderServiceData: data,
        getForOrderServiceLoading: loading,
        getForOrderServiceError: error,
        getForOrderServiceFetch: fetch,
    } = useApiMethod({ api: agbs.getForOrderService });

    useEffect(() => {
        if (type && country) {
            fetch({ type, country });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, country]);

    if (loading || !data) {
        return <Loader />;
    }

    if (error) {
        return (
            <ToastPlate view="negative" title="Error">
                { error?.message }
            </ToastPlate>
        );
    }

    return (
        <iframe
            src={ data.text }
            width="100%"
            height="500px"
            title="document"
            style={ { border: 0 } }
        />
    );
};
