import React from 'react';

import { Logo } from './components/logo';
import App from './app';

export const Root = () => (
    <React.Fragment>
        <div className="container">
            <div className="content">
                <Logo />
                <div className="wrapper">
                    <App />
                </div>
            </div>
        </div>

        <div id="modal-root" />
    </React.Fragment>
);
