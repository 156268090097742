/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TrashS } from '@alphakits/icons';
import {
    Button,
    CalendarInput,
    Checkbox,
    Flex,
    FlexColumns,
    Input,
    RadioGroup,
    Tag,
    Typography,
} from '@alphakits/ui/dist';
import { ClientTypeEnum } from '@escapenavigator/types/dist/client/emun/client-type.enum';
import { OrderForSignRO } from '@escapenavigator/types/dist/order/waivers/order-to-sign.ro';
import { SignOrderDTO } from '@escapenavigator/types/dist/order/waivers/sign-order.dto';
import { convertUTCDateToZonedDate, serializeRecord } from '@escapenavigator/utils';
import { FormikProps, FormikValues, setNestedObjectValues } from 'formik';

import { Participants } from './participants';
import { waiverUrl } from '.';

import styles from './index.module.css';

type Props = {
    next: () => void;
    order: OrderForSignRO;
} & Partial<FormikProps<FormikValues & SignOrderDTO>>;

export const FormStep: React.FC<Props> = ({
    next,
    handleChange,
    setTouched,
    validateForm,
    touched,
    errors,
    values,
    setFieldValue,
    order,
}) => {
    const { t, i18n } = useTranslation();

    const notSignedKids = order.participants.filter(
        (p) => p.client.clientType === ClientTypeEnum.MINOR && !p.orderWaiverId,
    );
    const notSignedAdults = order.participants.filter(
        (p) => p.client.clientType === ClientTypeEnum.ADULT && !p.orderWaiverId,
    );

    return (
        <React.Fragment>
            <Flex className={ styles.head } align="center" direction="column" gap="xs">
                <img src={ waiverUrl } alt="" width={ 130 } />

                <Typography.Title tag="div" weight="bold" view="xsmall" color="primary">
                    { t('Вы приглашены на игру') }
                </Typography.Title>

                <Typography.Text tag="div" color="primary" view="primary-medium">
                    { order.questroom.title },{ ' ' }
                    { convertUTCDateToZonedDate({
                        date: order.utcDate,
                        timeZone: order.questroom.location.timeZone,
                        showTZ: false,
                    }) }
                    .
                </Typography.Text>

                <Typography.Text tag="div" color="secondary" view="primary-medium">
                    { t('Для подтверждения') }
                </Typography.Text>
            </Flex>

            <Participants participants={ order.participants } players={ order.players } />

            <FlexColumns columns={ 1 } gr={ 12 }>
                <Typography.Title tag="div" view="xxsmall" color="primary" weight="bold">
                    { t('Ваши контактные данные') }
                </Typography.Title>

                { !!notSignedAdults.length && (
                    <RadioGroup
                        view="default"
                        gr={ 12 }
                        selected={ values.existingParticipantId }
                        onChange={ (value) => setFieldValue('existingParticipantId', value) }
                        data={ [
                            ...notSignedAdults.map((p) => ({
                                key: p.id,
                                content: `${p.client.name} ${p.client.surname}`,
                            })),
                            {
                                key: 0,
                                content: t('Другой участник'),
                            },
                        ] }
                    />
                ) }

                { !values.existingParticipantId && (
                    <React.Fragment>
                        <Input
                            label={ t('Имя') }
                            value={ values?.name }
                            block={ true }
                            onChange={ handleChange('name') }
                            error={ touched?.name && errors?.name }
                        />

                        <Input
                            label={ t('фамилия') }
                            value={ values.surname }
                            block={ true }
                            onChange={ handleChange('surname') }
                            error={ touched?.surname && errors?.surname }
                        />

                        <Input
                            label={ t('Email') }
                            value={ values.email }
                            block={ true }
                            onChange={ handleChange('email') }
                            error={ touched?.email && errors?.email }
                        />

                        <Input
                            label={ t('Телефон') }
                            value={ values?.phone }
                            block={ true }
                            onChange={ handleChange('phone') }
                            error={ touched?.phone && errors?.phone }
                        />
                    </React.Fragment>
                ) }
            </FlexColumns>

            <FlexColumns columns={ 1 } gr={ 24 }>
                <FlexColumns columns={ 1 } gr={ 4 }>
                    <Typography.Title tag="div" weight="bold" view="xxsmall">
                        { t('Несовершеннолетние') }
                    </Typography.Title>
                    <Typography.Text tag="div" color="primary" view="primary-medium">
                        { t('Если на игре будут дети') }
                    </Typography.Text>
                </FlexColumns>

                { notSignedKids.map((p) => (
                    <Checkbox
                        onChange={ (_, { checked }) => {
                            setFieldValue(
                                'existingChilds',
                                checked
                                    ? [...values.existingChilds, p.id]
                                    : values.existingChilds.filter((c) => c !== p.id),
                            );
                        } }
                        checked={ values.existingChilds?.includes(p.id) }
                        label={ `${p.client.name} ${p.client.surname}` }
                    />
                )) }

                { values.childs?.map((c, i) => {
                    const err = errors.childs?.[i] as any;

                    return (
                        <FlexColumns key={ i } columns={ 1 } gr={ 12 }>
                            <Flex>
                                <Typography.Text view="title">
                                    { t('Ребенок') } { i + 1 }
                                </Typography.Text>

                                <Button
                                    view="ghost"
                                    size="xs"
                                    leftAddons={ <TrashS /> }
                                    onClick={ () => {
                                        values.childs.splice(i, 1);
                                        setFieldValue('childs', values.childs);
                                    } }
                                />
                            </Flex>

                            <Input
                                label={ t('Имя') }
                                value={ c?.name }
                                block={ true }
                                onChange={ handleChange(`childs.${i}.name`) }
                                error={ touched.childs?.[i]?.name && err?.name }
                            />
                            <Input
                                label={ t('фамилия') }
                                value={ c?.surname }
                                block={ true }
                                onChange={ handleChange(`childs.${i}.surname`) }
                                error={ touched.childs?.[i]?.surname && err?.surname }
                            />
                            <CalendarInput
                                lang={ i18n.language }
                                calendarProps={ {
                                    defaultView: 'months',
                                } }
                                mobileMode="popover"
                                block={ true }
                                label={ t('Дата рождения') }
                                value={ c?.birthday }
                                error={ touched.childs?.[i]?.birthday && err?.birthday }
                                onChange={ (e, { value }) =>
                                    setFieldValue(`childs.${i}.birthday`, value) }
                            />
                        </FlexColumns>
                    );
                }) }

                <div>
                    <Button
                        view="outlined"
                        size="xs"
                        onClick={ () =>
                            setFieldValue('childs', [
                                ...(values?.childs || []),
                                ...serializeRecord(SignOrderDTO, {
                                    childs: [
                                        {
                                            name: '',
                                        },
                                    ],
                                }).childs,
                            ]) }
                    >
                        { t('Добавить') }
                    </Button>
                </div>
            </FlexColumns>

            { !!values.childs?.length && (
                <FlexColumns columns={ 1 }>
                    <Typography.Text view="title" weight="bold">
                        { t('Вы участник') }
                    </Typography.Text>

                    <Flex wrap={ true } gap="sm" justify="start">
                        <Tag
                            onClick={ () => setFieldValue('isParticipant', true) }
                            text={ t('common:Да') }
                            view={ values.isParticipant ? 'primary-inverted' : 'primary' }
                        />

                        <Tag
                            onClick={ () => setFieldValue('isParticipant', false) }
                            text={ t('common:Нет') }
                            view={ values.isParticipant ? 'primary' : 'primary-inverted' }
                        />
                    </Flex>
                </FlexColumns>
            ) }

            <Button
                size="s"
                view="primary"
                onClick={ async () => {
                    const validationErrors = await validateForm(values);

                    if (Object.keys(validationErrors).length > 0) {
                        setTouched(setNestedObjectValues(validationErrors, true));

                        return;
                    }

                    next();
                } }
            >
                { t('Далее') }
            </Button>
        </React.Fragment>
    );
};
