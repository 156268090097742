import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        ns: ['orders-service', 'validationErrors', 'widget-guide', 'common', 'widgets'],
        // saveMissing: true,
        defaultNS: 'orders-service',
        fallbackLng: 'en',
        supportedLngs: ['en', 'de', 'et', 'ru', 'es', 'pt', 'pl', 'fr', 'it', 'el', 'nl', 'cs'],
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: 'https://translations.escapenavigator.com/{{lng}}/{{ns}}.json',
            // addPath: 'https://gateway.escapenavigator.com/missing-translations/add/{{lng}}/{{ns}}',
            overrideMimeType: 'application/json',
            // crossDomain: true,
            // requestOptions: {
            //     mode: 'no-cors',
            //     cache: 'default',
            // },
            // withCredentials: false,
            // customHeaders: {
            //   authorization: 'foo',
            //   // ...
            // },
            // queryStringParams: { v: '1.3.5' },
            // reloadInterval: false
        },
    });
