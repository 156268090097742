import React from 'react';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '@alphakits/ui';
import { Button } from '@alphakits/ui/dist/button';

export type SignWaiverProps = {
    orderToken: string;
    questroomTitle: string;
    signed?: boolean;
};

export const SignWaiver: React.FC<SignWaiverProps> = ({ orderToken, questroomTitle, signed }) => {
    const { t } = useTranslation('widgets');

    const linkToCopy = `${process.env.REACT_APP_ORDERS_DOMAIN}?waiver=${orderToken}`;

    const handleShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: t('shareWaiverTitle'),
                    text: t('shareWaiverText', { title: questroomTitle }),
                    url: linkToCopy,
                })
                .then(() => {
                    showSuccess('Waiver sent');
                });
        } else {
            navigator.clipboard.writeText(linkToCopy).then(
                () => {
                    showSuccess(t('Ссылка скопирована описание'), t('Ссылка скопирована'));
                },
                () => {
                    showError('Please, try to sign waiver first.');
                },
            );
        }
    };

    return (
        <React.Fragment>
            { !signed && (
                <Button
                    block={ true }
                    href={ `${process.env.REACT_APP_ORDERS_DOMAIN}?waiver=${orderToken}&owner=true` }
                    target="_blank"
                    view="outlined"
                    size="xs"
                >
                    { t('Подписать') }
                </Button>
            ) }

            <Button block={ true } onClick={ handleShare } view="link" size="xs">
                { t('Поделиться ссылкой') }
            </Button>
        </React.Fragment>
    );
};
