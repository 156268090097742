import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyPage } from '@alphakits/ui/dist';

type Props = {
    backLink: string;
};

export const SucceedPayment: React.FC<Props> = ({ backLink = '' }) => {
    const { t } = useTranslation();

    return (
        <EmptyPage
            title={ backLink.includes('order') ? t('Игра оплачена') : t('Сертификат оплачен') }
            image="https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget/widgetComplereOrder.webp"
            subtitle={ t('Мы приняли ваш платеж') }
            buttonText={ t('common:back') }
            view="outlined"
            size="s"
            href={ backLink }
        />
    );
};
