import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { OpenapiCreatePaymentRO } from '@escapenavigator/types/dist/openapi/shared/openapi-create-payment.ro';
import { OpenapiGetPaymentMethodsRO } from '@escapenavigator/types/dist/openapi/shared/openapi-get-payment-methods.ro';
import { useApi } from 'src/providers/api/context';
import { setSucceed } from 'src/utils/set-succeed';

import { PaypalProps } from '../payment/paypal-button';

type Props = {
    token?: string;
    failedPayment: boolean;
};

export type PaymentType = 'full' | 'half';

export const useOrderPayment = ({ token, failedPayment }: Props) => {
    const { t } = useTranslation();
    const { openapiWidget } = useApi();
    const [error, setError] = useState(failedPayment ? t('Не удалось провести платеж') : '');
    const [paymentData, setPaymentData] = useState<OpenapiCreatePaymentRO | null>();

    const clearPaymentData = useCallback(() => {
        setPaymentData(null);
    }, []);

    const { orderStripePaymentFetch, orderStripePaymentLoading } = useApiMethod({
        api: openapiWidget.orderStripePayment,
        successCallback: ({ data }) => {
            setPaymentData(data);
        },
        errorCallback: ({ message }) => setError(message),
    });

    const { capturePaypalOrderFetch, capturePaypalOrderLoading } = useApiMethod({
        api: openapiWidget.capturePaypalOrder,
        successCallback: () => {
            setSucceed();
        },
        errorCallback: ({ message }) => setError(message),
    });

    const capturePaypal: PaypalProps['capturePayment'] = (data) =>
        capturePaypalOrderFetch({
            orderToken: token,
            paymentId: data.orderID,
        });

    const { orderPaypalPaymentFetch, orderPaypalPaymentLoading } = useApiMethod({
        api: openapiWidget.orderPaypalPayment,
        errorCallback: ({ message }) => setError(message),
    });

    const createStripePayment = useCallback(
        (paymentMethod: OpenapiGetPaymentMethodsRO) => {
            orderStripePaymentFetch({
                orderToken: token,
                includedIncrease: paymentMethod.full.increase,
                amount: paymentMethod.full.amountWithIncrease,
            });
        },
        [token, orderStripePaymentFetch],
    );

    const createPaypalPayment = useCallback(
        (paymentMethod: OpenapiGetPaymentMethodsRO) =>
            orderPaypalPaymentFetch({
                orderToken: token,
                includedIncrease: paymentMethod.full.increase,
                amount: paymentMethod.full.amountWithIncrease,
            }).then(({ data }) => {
                setError('');

                return data.clientSecret;
            }),
        [token, orderPaypalPaymentFetch],
    );

    const loading =
        capturePaypalOrderLoading || orderPaypalPaymentLoading || orderStripePaymentLoading;

    return {
        loading,
        error,
        paymentData,
        capturePaypal,
        setPaymentData,
        setError,
        clearPaymentData,
        createPaypalPayment,
        createStripePayment,
    };
};
