import React from 'react';

import { AlphaLogo } from './alpha-logo';

import styles from './index.module.css';

export const Logo: React.FC = () => (
    <div className={ styles.logo }>
        <AlphaLogo />
        Escape Navigator
    </div>
);
