import React from 'react';
import { useTranslation } from 'react-i18next';
import { Amount, formatDate, SuperListItem } from '@alphakits/ui/dist';
import { OpenapiOrderRO } from '@escapenavigator/types/dist/openapi/orders/openapi-order.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { TransactionTypeEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-type.enum';

type Props = {
    transaction: OpenapiOrderRO['transactions'][0];
    currency: ProfileCurrencyEnum;
};

const getStatus = (type: TransactionTypeEnum, t) => {
    switch (type) {
        case TransactionTypeEnum.PROCESSING:
            return `(${t('Обрабатывается')})`;

        case TransactionTypeEnum.CANCELED:
            return `(${t('Отклонен')})`;

        default:
            return '';
    }
};

export const Transaction: React.FC<Props> = ({ transaction, currency }) => {
    const { t, i18n } = useTranslation();

    const text = transaction.amount < 0 ? t('Возврат ') : t('Платеж');
    const status = getStatus(transaction.type, t);

    return (
        <div style={ { opacity: status ? 0.5 : 1 } }>
            <SuperListItem
                key={ transaction.id }
                text={ ` ${text} ${
                    transaction.type !== TransactionTypeEnum.INSIDE ? t('онлайн') : ''
                } ${status}` }
                disabled={ true }
                color={ transaction.amount < 0 ? 'negative' : 'primary' }
                hint={ formatDate(transaction.createdAt, { lang: i18n.language }) }
                rightAddons={ (
                    <Amount
                        value={ transaction.amount }
                        currency={ currency }
                        color="primary"
                        type="decimal"
                        weight="bold"
                        view="title"
                    />
                ) }
            />
        </div>
    );
};
