import { StatusProps } from '@alphakits/ui/dist';
import { OpenapiOrderRO } from '@escapenavigator/types/dist/openapi/orders/openapi-order.ro';
import { differenceInHours } from 'date-fns';
import { TFunction } from 'i18next';

type Res = {
    status: { title: string; color: StatusProps['color'] };
    gameDate: Date;
    pastGame: boolean;
};

export const getOrderData = (order: OpenapiOrderRO, t: TFunction): Res => {
    const gameDate = new Date(order.utcDate);
    const pastGame = differenceInHours(gameDate, new Date()) < 0;
    const canceledGameTitle = order.canceledWithFine
        ? t('Отмененная со штрафом')
        : t('Отмененная в срок');
    const payed = order.total === order.payed;
    const canceled = !order.slot;

    let status: Res['status'];

    if (canceled) {
        status = {
            color: payed ? 'grey' : 'red',
            title: canceledGameTitle,
        };
    } else if (pastGame) {
        if (order.payed > order.total) {
            status = {
                color: 'red',
                title: t('Переплата'),
            };
        } else {
            status = {
                color: payed ? 'green' : 'red',
                title: payed ? t('Оплачена') : t('Не оплачена'),
            };
        }
    } else if (order.payed > order.total) {
        status = {
            color: 'purple',
            title: t('Переплата'),
        };
    } else if (!payed && order.payed < order.total && order.payed) {
        status = {
            color: 'orange',
            title: t('Предоплачена'),
        };
    } else {
        status = {
            color: payed ? 'green' : 'blue',
            title: payed ? t('Оплачена') : t('Предстоящая'),
        };
    }

    return {
        status,
        gameDate,
        pastGame,
    };
};
