/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { OpenS } from '@alphakits/icons';
import {
    Button,
    Cell,
    CopyButton,
    Flex,
    FlexColumns,
    Status,
    Typography,
} from '@alphakits/ui/dist';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { WidgetRO } from '@escapenavigator/types/dist/widget/widget.ro';

import styles from './index.module.css';

type Props = {
    widgets: WidgetRO[];
    lang: Languages;
    title: string;
    description: string;
    widgetTitle: (widget: WidgetRO) => string;
    widgetImage: (widget: WidgetRO) => React.ReactNode;
};

export const WidgetGuide: React.FC<Props> = ({
    widgets,
    lang,
    description,
    title,
    widgetTitle,
    widgetImage,
}) => {
    const getCode = (uuid) =>
        `<div data-widget-id="${uuid}" data-theme="light" data-language="${lang}" data-widget-type="schedule"></div>`;

    return (
        <FlexColumns columns={ 1 } gr={ 12 }>
            <FlexColumns columns={ 1 } gr={ 8 }>
                <Typography.Text tag="div" view="title" weight="bold">
                    { title }
                </Typography.Text>

                <Typography.Text tag="div" view="primary-medium" color="primary">
                    { description }
                </Typography.Text>
            </FlexColumns>

            <FlexColumns columns={ 1 } gr={ 4 }>
                { widgets?.map((widget) => {
                    const code = getCode(widget.uuid);

                    return (
                        <Flex gap="md">
                            <Flex className={ styles.cell }>
                                <Cell.Base
                                    title={ (
                                        <Flex justify="start" gap="md">
                                            { widgetTitle(widget) }
                                            <Status
                                                view="contrast"
                                                className={ styles.status }
                                                color={ widget.activity ? 'green' : 'grey' }
                                            >
                                                { widget.activity ? 'Installed' : 'Not installed' }
                                            </Status>
                                        </Flex>
                                    ) }
                                    addon={ widgetImage(widget) }
                                />
                            </Flex>

                            <Flex justify="end" gap="sm">
                                <pre className={ styles.pre }>
                                    <CopyButton text={ `${code.slice(0, 15)}...` } fullText={ code } />
                                </pre>
                                { widget.url && (
                                    <Button
                                        rightAddons={ <OpenS /> }
                                        onClick={ () => {
                                            window.open(widget.url, '_blank').focus();
                                        } }
                                        view="outlined"
                                        size="xs"
                                    >
                                        Setup here
                                    </Button>
                                ) }
                            </Flex>
                        </Flex>
                    );
                }) }
            </FlexColumns>
        </FlexColumns>
    );
};
