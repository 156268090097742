/* eslint-disable complexity */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Amount,
    Divider,
    FlexColumns,
    formatDate,
    InfoBlockHeader,
    InfoBlockItem,
    InfoBlockWrapper,
    Loader,
    Padding,
    SidepanelHeader,
    Status,
    SuperListHeader,
    SuperListItem,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { CertificatesaleDeliveryTypeEnum } from '@escapenavigator/types/dist/certificate-sale/enum/certificatesales-delivery-type.enum';
import { TransactionTypeEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-type.enum';
import { convertUTCDateToZonedDate } from '@escapenavigator/utils/dist';
import { useApi } from 'src/providers/api/context';

import { DoubleModal, ModalRightSide, SidePanelHeaderWrapper } from '../double-modal';
import { PaymentMethods } from '../payment/payment-methods';
import { TransactionsList } from '../transactions-list';

import { getCertificateData } from './get-certificate-data';
import { useCertificatePayment } from './use-certificate-payment';

import styles from './index.module.css';

type Props = {
    certificateToken: string;
    failedPayment: boolean;
};

export const Certificate: React.FC<Props> = ({ certificateToken, failedPayment }) => {
    const { t, i18n } = useTranslation();

    const { openapiWidget } = useApi();

    const {
        paymentData,
        capturePaypal,
        createPaypalPayment,
        createStripePayment,
        loading: paymentLoading,
        error: paymentError,
        setPaymentData,
        setError,
    } = useCertificatePayment({ failedPayment, certificateToken });

    const { getCertificatePaymentMethodsData, getCertificatePaymentMethodsFetch } = useApiMethod({
        api: openapiWidget.getCertificatePaymentMethods,
    });

    const {
        findCertificateData: certificate,
        findCertificateError,
        findCertificateLoading,
        findCertificateFetch,
    } = useApiMethod({
        api: openapiWidget.findCertificate,
        successCallback: ({ data }) => {
            if (data.toPay > 0) {
                getCertificatePaymentMethodsFetch({ certificateToken });
            }
        },
    }) as any;

    useEffect(() => {
        findCertificateFetch({ certificateToken });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificateToken]);

    const deliveryData = useMemo(() => {
        if (!certificate) {
            return {};
        }
        if (certificate.deliveryType === CertificatesaleDeliveryTypeEnum.EMAIL) {
            const hint = certificate.sendingEmailFullUtcDate
                ? convertUTCDateToZonedDate({
                    date: certificate.sendingEmailFullUtcDate,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })
                : t('Отправка после оплаты');

            return {
                title: 'Email',
                hint: `${certificate.deliveryEmail}, ${hint}`,
            };
        }
        if (certificate.deliveryType === CertificatesaleDeliveryTypeEnum.PICKUP) {
            return {
                title: t('Самовывоз'),
                hint: t('Необходимо позвонить на квест и согласовать место самовывоза'),
            };
        }

        return {
            title: t('Почтовая служба'),
            hint: certificate.deliveryAddress
                ? `${certificate.deliveryName} ${certificate.deliverySurname}, ${certificate.deliveryAddress}`
                : t('Не указаны детали отправки'),
        };
    }, [certificate, t]);

    if (findCertificateLoading || !certificate) {
        return <Loader />;
    }

    if (findCertificateError) {
        return (
            <ToastPlate view="negative" title="Error">
                { findCertificateError?.message }
            </ToastPlate>
        );
    }

    const certificateData = getCertificateData(certificate, t);

    const { client } = certificate;
    const metadataQuery = `certificate=${certificateToken}&payee=${paymentData?.payee}&paymentStatusPage`;

    const increase = certificate.transactions.reduce((acc, transaction) => {
        if (
            transaction.type === TransactionTypeEnum.PROCESSING ||
            transaction.type === TransactionTypeEnum.SUCCEEDED
        ) {
            return acc + transaction.includedIncrease;
        }

        return acc;
    }, 0);

    return (
        <DoubleModal>
            <div>
                <Padding padding="0 32px 40px" className={ styles.inner_wrapper }>
                    <React.Fragment>
                        <SidePanelHeaderWrapper>
                            <SidepanelHeader
                                title={ `Certificate № ${certificate.code}` }
                                bottomAddons={ (
                                    <Status view="contrast" color={ certificateData.color }>
                                        { ' ' }
                                        { certificateData.title }
                                    </Status>
                                ) }
                            />
                        </SidePanelHeaderWrapper>
                        <FlexColumns columns={ 1 } gr={ 8 }>
                            <SuperListItem
                                text={ t('Годен до') }
                                disabled={ true }
                                rightAddons={ formatDate(certificate.expireDate, {
                                    lang: i18n.language,
                                    format: 'dd MMMM yyyy',
                                }) }
                            />

                            <SuperListItem
                                disabled={ true }
                                hint={ deliveryData.hint }
                                text={ t('Способ доставки') }
                                rightAddons={ (
                                    <Typography.Text view="primary-medium" color="primary">
                                        { deliveryData.title }
                                    </Typography.Text>
                                ) }
                            />

                            { certificate.deliveryTrackingInfo && (
                                <SuperListItem
                                    disabled={ true }
                                    rightAddons={ (
                                        <Typography.Text view="primary-medium" color="primary">
                                            { certificate.deliveryTrackingInfo || '-' }
                                        </Typography.Text>
                                    ) }
                                    text={ t('Трек номер') }
                                />
                            ) }

                            <Divider size="s" />

                            <SuperListHeader
                                text={ t('Стоимость сертификата') }
                                rightAddons={ (
                                    <Amount
                                        value={ certificate.total }
                                        currency={ certificate.currency }
                                        color="primary"
                                        weight="bold"
                                        type="decimal"
                                        view="title"
                                    />
                                ) }
                            />

                            <SuperListItem
                                text={ t('Номинльная стоимость') }
                                disabled={ true }
                                rightAddons={ (
                                    <Amount
                                        value={ certificate.nominal }
                                        currency={ certificate.currency }
                                        color="primary"
                                        weight="bold"
                                        type="decimal"
                                        view="title"
                                    />
                                ) }
                            />

                            <SuperListItem
                                text={ t('Наценка на сертиификат') }
                                disabled={ true }
                                rightAddons={ (
                                    <Amount
                                        value={ certificate.extraPrice || 0 }
                                        currency={ certificate.currency }
                                        color="primary"
                                        weight="bold"
                                        type="decimal"
                                        view="title"
                                    />
                                ) }
                            />

                            <SuperListItem
                                text={ t('Стоимость доставки') }
                                disabled={ true }
                                rightAddons={ (
                                    <Amount
                                        value={ certificate.deliveryPrice || 0 }
                                        currency={ certificate.currency }
                                        color="primary"
                                        weight="bold"
                                        type="decimal"
                                        view="title"
                                    />
                                ) }
                            />

                            { !!increase && (
                                <SuperListItem
                                    text={ t('Наценка за онлайн оплату') }
                                    disabled={ true }
                                    rightAddons={ (
                                        <Amount
                                            value={ increase }
                                            currency={ certificate.currency }
                                            color="primary"
                                            weight="bold"
                                            type="decimal"
                                            view="title"
                                        />
                                    ) }
                                />
                            ) }

                            <Divider size="s" />

                            <SuperListHeader
                                text={ t('Платежи') }
                                rightAddons={ (
                                    <Amount
                                        value={ +certificate.payed }
                                        currency={ certificate.currency }
                                        color="primary"
                                        weight="bold"
                                        type="decimal"
                                        view="title"
                                    />
                                ) }
                            />

                            <TransactionsList
                                currency={ certificate.currency }
                                transactions={ certificate.transactions }
                            />

                            { certificate.discounts?.map((discount) => (
                                <SuperListItem
                                    key={ discount.id }
                                    text={ t('Ручная скидка') }
                                    hint={ `${formatDate(discount.createdAt, {
                                        lang: i18n.language,
                                    })}, ${discount.reason}` }
                                    disabled={ true }
                                    rightAddons={ (
                                        <Amount
                                            value={ discount.amount }
                                            currency={ certificate.currency }
                                            color="primary"
                                            weight="bold"
                                            type="decimal"
                                            view="title"
                                        />
                                    ) }
                                />
                            )) }

                            <TransactionsList currency={ certificate.currency } transactions={ [] } />
                        </FlexColumns>
                        <Divider size="m" />
                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <SuperListHeader
                                text={ t('Итого к оплате') }
                                rightAddons={ (
                                    <Amount
                                        value={ certificate.toPay }
                                        currency={ certificate.currency }
                                        color="primary"
                                        weight="bold"
                                        type="decimal"
                                        view="title"
                                    />
                                ) }
                            />

                            { paymentError && (
                                <ToastPlate
                                    hasCloser={ true }
                                    onClose={ () => setError('') }
                                    view="negative"
                                    title="Error"
                                >
                                    { paymentError }
                                </ToastPlate>
                            ) }

                            { !!getCertificatePaymentMethodsData?.length && (
                                <PaymentMethods
                                    allPaymentMethods={ getCertificatePaymentMethodsData }
                                    currency={ certificate.currency }
                                    createPaypalPayment={ createPaypalPayment }
                                    createStripePayment={ createStripePayment }
                                    capturePaypal={ capturePaypal }
                                    loading={ paymentLoading }
                                    setFailed={ () => {
                                        setError('Payment failed');
                                        setPaymentData(undefined);
                                    } }
                                    metadataQuery={ metadataQuery }
                                    paymentData={ paymentData }
                                />
                            ) }
                        </FlexColumns>
                    </React.Fragment>
                </Padding>
            </div>

            <ModalRightSide loading={ false }>
                <InfoBlockWrapper>
                    <InfoBlockHeader title={ t('Покупатель') } />
                    { (client?.name || client?.surname) && (
                        <InfoBlockItem
                            label={ t('Имя') }
                            value={ `${client?.name || ''} ${client?.surname || ''}` }
                        />
                    ) }
                    <InfoBlockItem label={ t('Телефон') } value={ client?.phone } />
                    <InfoBlockItem label={ t('Email') } value={ client?.email } />
                </InfoBlockWrapper>
                <InfoBlockWrapper>
                    <InfoBlockHeader title={ t('Детали сертификата') } />
                    <InfoBlockItem label={ t('Дата создания') } value={ certificate.createdAt } />
                    <InfoBlockItem label={ t('Телефон') } value={ certificate.phone } />
                </InfoBlockWrapper>
            </ModalRightSide>
        </DoubleModal>
    );
};
