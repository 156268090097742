import React from 'react';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { OnApproveActions, OnApproveData } from '@paypal/paypal-js/types/components/buttons';
import {
    PayPalButtons,
    PayPalScriptProvider,
    usePayPalScriptReducer,
} from '@paypal/react-paypal-js';

export type PaypalProps = {
    createPayment: () => Promise<string>;
    currency: ProfileCurrencyEnum;
    loading: boolean;
    capturePayment: (data: OnApproveData, actions: OnApproveActions) => Promise<any>;
};

const ButtonWrapper = ({
    loading,
    capturePayment,
    createPayment,
}: Omit<PaypalProps, 'currency'>) => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
        <PayPalButtons
            style={ {
                label: 'checkout',
                color: 'blue',
            } }
            disabled={ isPending || loading }
            fundingSource="paypal"
            forceReRender={ [createPayment] }
            createOrder={ createPayment }
            onApprove={ capturePayment }
        />
    );
};

export const PaypalButton = (props: PaypalProps) => (
    <PayPalScriptProvider
        options={ {
            clientId: process.env.REACT_APP_PAYPAL_USER_ID,
            components: 'buttons',
            intent: 'capture',
            currency: props.currency,
        } }
    >
        <ButtonWrapper { ...props } />
    </PayPalScriptProvider>
);
