import React from 'react';
import { Skeleton } from '@alphakits/ui/dist';

import styles from './index.module.css';

export const DoubleModal: React.FC = ({ children }) => (
    <div className={ styles.double_modal }>{ children }</div>
);

export const SidePanelHeaderWrapper: React.FC = ({ children }) => (
    <div className={ styles.header_wrapper }>{ children }</div>
);

type Props = {
    loading: boolean;
};

export const ModalRightSide: React.FC<Props> = ({ children, loading }) => (
    <div>
        { loading ? (
            <React.Fragment>
                <Skeleton visible={ true } className={ styles.image_skeleton } />
                <Skeleton visible={ true } className={ styles.info_skeleton } />
            </React.Fragment>
        ) : (
            <React.Fragment>{ children }</React.Fragment>
        ) }
    </div>
);
