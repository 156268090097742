/* eslint-disable complexity */
import React, { useEffect } from 'react';
import { Loader, ToastPlate } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { useApi } from 'src/providers/api/context';

import { HtmlRenderer } from '../html-renderer';

type Props = {
    profileId: string;
};

export const ProfileAgb: React.FC<Props> = ({ profileId }) => {
    const { profiles } = useApi();

    const {
        getProfileAgbForOrderServiceData: data,
        getProfileAgbForOrderServiceLoading: loading,
        getProfileAgbForOrderServiceError: error,
        getProfileAgbForOrderServiceFetch: fetch,
    } = useApiMethod({ api: profiles.getProfileAgbForOrderService });

    useEffect(() => {
        if (profileId) fetch(+profileId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileId]);

    if (loading || !data) {
        return <Loader />;
    }

    if (error) {
        return (
            <ToastPlate view="negative" title="Error">
                { error?.message }
            </ToastPlate>
        );
    }

    return <HtmlRenderer text={ data.text } />;
};
