/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TrashS } from '@alphakits/icons';
import {
    Button, Flex, FlexColumns, InfoBlockItem, Typography,
} from '@alphakits/ui/dist';
import { OrderForSignRO } from '@escapenavigator/types/dist/order/waivers/order-to-sign.ro';

type Props = {
    participants: OrderForSignRO['participants'];
    players: number;
    onDelete?: (id: number) => void;
};

export const Participants: React.FC<Props> = ({ participants, players, onDelete }) => {
    const { t } = useTranslation();

    return (
        <FlexColumns columns={ 1 } gr={ 24 }>
            <FlexColumns columns={ 1 } gr={ 12 }>
                <Typography.Title tag="div" view="xxsmall" color="primary" weight="bold">
                    { t('Участники игры') }
                </Typography.Title>

                <FlexColumns columns={ 1 } gr={ 0 }>
                    { new Array(Math.max(participants.length, players)).fill(0).map((_, i) => {
                        const participant = participants?.[i];
                        const getColor = () => {
                            if (!participant) return 'negative';

                            if (participant.orderWaiverId) return 'positive';

                            return 'attention';
                        };

                        return (
                            <InfoBlockItem
                                key={ i }
                                label={ `${t('Игрок')} ${i + 1}` }
                                value={
                                    participant ? (
                                        <Flex gap="xs">
                                            <span>
                                                { `${participant.client.name} ${participant.client.surname}` }
                                            </span>
                                            { onDelete && (
                                                <Button
                                                    view="ghost"
                                                    size="xs"
                                                    leftAddons={
                                                        <TrashS color="var(--color-text-secondary)" />
                                                    }
                                                    onClick={ () =>
                                                        // eslint-disable-next-line no-restricted-globals
                                                        confirm(
                                                            'This participant will be required to sign the waiver again after removal.',
                                                        ) && onDelete(participant.id) }
                                                />
                                            ) }
                                        </Flex>
                                    ) : (
                                        t('Не указан')
                                    )
                                }
                                color={ getColor() }
                            />
                        );
                    }) }
                </FlexColumns>
            </FlexColumns>
        </FlexColumns>
    );
};
