import { StatusProps } from '@alphakits/ui/dist';
import { CertificatesaleDeliveryTypeEnum } from '@escapenavigator/types/dist/certificate-sale/enum/certificatesales-delivery-type.enum';
import { OpenapiCertificateSaleRO } from '@escapenavigator/types/dist/openapi/certificates/openapi-certificate-sale.ro';
import { differenceInHours, parse } from 'date-fns';
import { TFunction } from 'i18next';

type Res = {
    title: string;
    color: StatusProps['color'];
    type?: 'used' | 'expired' | 'not_sended' | 'notPayed' | 'extraPayed';
};

export const getCertificateData = (certifcate: OpenapiCertificateSaleRO, t: TFunction): Res => {
    const expiried =
        differenceInHours(parse(certifcate.expireDate, 'yyyy-MM-dd', new Date()), new Date()) < 0;

    if (certifcate.order?.id) {
        return {
            color: 'purple',
            title: t('Использован'),
            type: 'used',
        };
    }

    if (expiried) {
        return {
            color: 'grey',
            title: t('Просрочен'),
            type: 'expired',
        };
    }

    if (certifcate.toPay > 0) {
        return {
            color: 'red',
            title: t('Не оплачен'),
            type: 'notPayed',
        };
    }

    if (certifcate.toPay < 0) {
        return {
            color: 'purple',
            title: t('Переплата'),
            type: 'extraPayed',
        };
    }

    if (certifcate.sended) {
        return {
            color: 'green',
            title: t('Отправлен'),
        };
    }

    if (certifcate.deliveryType === CertificatesaleDeliveryTypeEnum.EMAIL) {
        return {
            color: 'green',
            title: t('Запланирована отправка'),
        };
    }

    if (certifcate.deliveryType === CertificatesaleDeliveryTypeEnum.PICKUP) {
        return {
            color: 'blue',
            title: t('Ожидает самовывоз'),
        };
    }

    return {
        color: 'blue',
        title: t('Ожидает отправки'),
        type: 'not_sended',
    };
};
