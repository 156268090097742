import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import {
    defaultThemes, EmptyPage, THEME, ThemeProvider,
} from '@alphakits/ui';

import routes from './router/routes';

const Routes = () =>
    useRoutes([
        ...routes(),
        {
            path: '*',
            element: (
                <EmptyPage
                    title="OOOPS"
                    code="404"
                    buttonText="Вернуться на главную"
                    subtitle="Похоже, что такой страницы не существует"
                    href="/"
                />
            ),
        },
    ]);

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider themes={ defaultThemes } selectedTheme={ THEME.LIGHT }>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
