import React from 'react';
import {
    InitApiDeclaration,
    initClientApi,
} from '@escapenavigator/services/dist/api/init-client-api';

type Context = InitApiDeclaration | null;

export const ApiContext = React.createContext<Context>(null);

export const ApiProvider = ({ children }) => (
    <ApiContext.Provider
        value={ initClientApi(process.env.REACT_APP_API_DOMAIN || 'http://localhost:4000') }
    >
        { children }
    </ApiContext.Provider>
);

export const useApi = () => React.useContext(ApiContext);
