import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { OpenapiCreatePaymentRO } from '@escapenavigator/types/dist/openapi/shared/openapi-create-payment.ro';
import { OpenapiGetPaymentMethodsRO } from '@escapenavigator/types/dist/openapi/shared/openapi-get-payment-methods.ro';
import { useApi } from 'src/providers/api/context';
import { setSucceed } from 'src/utils/set-succeed';

import { PaypalProps } from '../payment/paypal-button';

type Props = {
    failedPayment?: boolean;
    certificateToken: string;
};

export const useCertificatePayment = ({ failedPayment, certificateToken }: Props) => {
    const { openapiWidget } = useApi();
    const { t } = useTranslation();
    const [paymentData, setPaymentData] = useState<OpenapiCreatePaymentRO | null>();
    const [error, setError] = useState(failedPayment ? t('Не удалось провести платеж') : '');

    // const {
    //     findCertificateData,
    //     findCertificateFetch,
    //     findCertificateError,
    //     findCertificateLoading,
    // } = useApiMethod({
    //     api: openapiWidget.findCertificate,
    // });

    // const {
    //     getCertificatePaymentMethodsFetch,
    //     getCertificatePaymentMethodsLoading,
    //     getCertificatePaymentMethodsError,
    // } = useApiMethod({
    //     api: openapiWidget.getCertificatePaymentMethods,
    //     successCallback: ({ data }) => {
    //         setAllPaymentMethods(data);
    //         setPaymentMethod(data[0]);
    //     },
    // });
    // const clearError = useCallback(() => {
    //     setError('');
    // }, []);

    // const clearPaymentData = useCallback(() => {
    //     setPaymentData(null);
    // }, []);

    // useEffect(() => {
    //     findCertificateFetch({ certificateToken });
    //     getCertificatePaymentMethodsFetch({ certificateToken });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const { certificateStripePaymentFetch, certificateStripePaymentLoading } = useApiMethod({
        api: openapiWidget.certificateStripePayment,
        errorCallback: ({ message }) => setError(message),
        successCallback: ({ data }) => {
            setPaymentData(data);
            setError('');
        },
    });

    const { capturePaypalCertificateFetch, capturePaypalCertificateLoading } = useApiMethod({
        api: openapiWidget.capturePaypalCertificate,
        successCallback: setSucceed,
        errorCallback: ({ message }) => setError(message),
    });

    const capturePaypal: PaypalProps['capturePayment'] = (data) =>
        capturePaypalCertificateFetch({
            certificateToken,
            paymentId: data.orderID,
        });

    const { certificatePaypalPaymentFetch, certificatePaypalPaymentLoading } = useApiMethod({
        api: openapiWidget.certificatePaypalPayment,
        errorCallback: ({ message }) => setError(message),
    });

    const createStripePayment = useCallback((paymentMethod: OpenapiGetPaymentMethodsRO) => {
        certificateStripePaymentFetch({
            certificateToken,
            includedIncrease: paymentMethod.full.increase,
            amount: paymentMethod.full.amountWithIncrease,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createPaypalPayment = useCallback(
        (paymentMethod: OpenapiGetPaymentMethodsRO) =>
            certificatePaypalPaymentFetch({
                certificateToken,
                includedIncrease: paymentMethod.full.increase,
                amount: paymentMethod.full.amountWithIncrease,
            }).then(({ data }) => {
                setError('');

                return data.clientSecret;
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const loading =
        certificateStripePaymentLoading ||
        capturePaypalCertificateLoading ||
        certificatePaypalPaymentLoading;

    return {
        loading,
        error,
        paymentData,
        capturePaypal,
        setPaymentData,
        setError,
        createPaypalPayment,
        createStripePayment,
    };
};
