/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, Checkbox, Flex, FlexColumns, PageLoading, Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { SignOrderDTO } from '@escapenavigator/types/dist/order/waivers/sign-order.dto';
import { FormikProps, FormikValues } from 'formik';
import { useApi } from 'src/providers/api/context';

import { SignaturePad } from '../sign';

import { waiverUrl } from '.';

import styles from './index.module.css';

type Props = {
    onBack: () => void;
    profileId: number;
} & Partial<FormikProps<FormikValues & SignOrderDTO>>;

export const SignStep: React.FC<Props> = ({
    onBack,
    submitForm,
    touched,
    errors,
    profileId,
    values,
    setFieldValue,
}) => {
    const { t } = useTranslation();

    const { profiles } = useApi();

    const {
        getProfileWaiverForOrderServiceLoading: loading,
        getProfileWaiverForOrderServiceFetch: fetch,
        getProfileWaiverForOrderServiceData: waiver,
    } = useApiMethod({
        api: profiles.getProfileWaiverForOrderService,
    });

    useEffect(() => {
        fetch(profileId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return <PageLoading />;

    return (
        <React.Fragment>
            <Flex className={ styles.head } align="center" direction="column" gap="xs">
                <img src={ waiverUrl } alt="" width={ 130 } />
            </Flex>

            <FlexColumns columns={ 1 } gr={ 4 }>
                <Typography.Title tag="div" weight="bold" view="xsmall" color="primary">
                    { waiver?.title }
                </Typography.Title>

                <div dangerouslySetInnerHTML={ { __html: waiver?.text } } />

                <FlexColumns columns={ 1 } gr={ 16 }>
                    <SignaturePad
                        dataUrl={ values.signBase64 }
                        onChange={ (signBase64) => setFieldValue('signBase64', signBase64) }
                    />

                    { touched.signBase64 && errors.signBase64 && (
                        <Typography.Text view="caps" color="negative">
                            Sign wiver before sending
                        </Typography.Text>
                    ) }

                    <Checkbox
                        label={ (
                            <Typography.Text
                                view="caps"
                                color={ touched.agree && errors.agree ? 'negative' : 'primary' }
                            >
                                { waiver?.label }
                            </Typography.Text>
                        ) }
                        align="start"
                        checked={ values.agree }
                        onChange={ (e, { checked }) => setFieldValue('agree', checked) }
                        block={ true }
                    />
                </FlexColumns>
            </FlexColumns>
            <Flex gap="md">
                <Button view="outlined" size="m" block={ true } onClick={ onBack }>
                    { t('common:back') }
                </Button>
                <Button view="primary" size="m" block={ true } onClick={ submitForm }>
                    { t('Подписать') }
                </Button>
            </Flex>
        </React.Fragment>
    );
};
