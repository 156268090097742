import React from 'react';
import { FlexColumns } from '@alphakits/ui';
import { OpenapiCreatePaymentRO } from '@escapenavigator/types/dist/openapi/shared/openapi-create-payment.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { StripeEliements } from './stripe-elements';

type Props = {
    setFailed: (error: string) => void;
    paymentData: OpenapiCreatePaymentRO;
    currency: ProfileCurrencyEnum;
    metadataQuery: string;
};

export const StripePayment: React.FC<Props> = ({
    paymentData,
    metadataQuery,
    setFailed,
    currency,
}) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN, {
        stripeAccount: paymentData.payee,
    });

    return (
        <React.Fragment>
            <FlexColumns columns={ 1 } gr={ 32 }>
                <Elements
                    options={ {
                        clientSecret: paymentData.clientSecret,
                        appearance: {
                            theme: 'stripe',
                            labels: 'floating',
                        },
                    } }
                    stripe={ stripePromise }
                >
                    <StripeEliements
                        amount={ paymentData.amount }
                        currency={ currency }
                        setFailed={ setFailed }
                        metadataQuey={ metadataQuery }
                    />
                </Elements>
            </FlexColumns>
        </React.Fragment>
    );
};
