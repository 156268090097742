import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Amount, Flex } from '@alphakits/ui';
import { Button } from '@alphakits/ui/dist/button';
import { FlexColumns } from '@alphakits/ui/dist/flex-columns';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { setSucceed } from 'src/utils/set-succeed';

type Props = {
    metadataQuey;
    amount: number;
    currency: ProfileCurrencyEnum;
    setFailed: (error: string) => void;
};

export const StripeEliements: React.FC<Props> = ({
    metadataQuey, setFailed, currency, amount,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) return;

        setIsLoading(true);

        stripe
            .confirmPayment({
                elements,
                redirect: 'if_required',
                confirmParams: {
                    return_url: `${window.location.href.split('?')[0]}?${metadataQuey}`,
                },
            })
            .then(({ error }) => {
                if (!error) return setSucceed();

                if (error.type === 'card_error' || error.type === 'validation_error') {
                    return setFailed(error.message);
                }

                return setFailed('An unexpected error occurred.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <form id="payment-form" onSubmit={ handleSubmit }>
            <FlexColumns gc={ 12 } columns={ 1 }>
                <PaymentElement
                    id="payment-element"
                    options={ {
                        layout: 'tabs',
                    } }
                />

                <Button
                    onClick={ handleSubmit }
                    block={ true }
                    loading={ isLoading }
                    disabled={ isLoading || !stripe || !elements }
                    view="primary"
                    size="s"
                >
                    <Flex justify="center" gap="xs">
                        { t('Оплатить') }

                        <Amount
                            type="decimal"
                            currencyOpacity={ false }
                            weight="bold"
                            currency={ currency }
                            value={ amount }
                        />
                    </Flex>
                </Button>
            </FlexColumns>
        </form>
    );
};
