import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Amount, Button, Flex, FlexColumns, Typography,
} from '@alphakits/ui';
import { PaymentMethodEnum } from '@escapenavigator/types/dist/openapi/orders/enum/payment-type.enum';
import { OpenapiCreatePaymentRO } from '@escapenavigator/types/dist/openapi/shared/openapi-create-payment.ro';
import { OpenapiGetPaymentMethodsRO } from '@escapenavigator/types/dist/openapi/shared/openapi-get-payment-methods.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';

import { HorizontalFlex } from '../../horizontal-flex';
import { PaymentMethodCell } from '../payment-method';
import { PaypalButton, PaypalProps } from '../paypal-button';
import { StripePayment } from '../stripe-payment';

type Props = {
    allPaymentMethods: OpenapiGetPaymentMethodsRO[];
    currency: ProfileCurrencyEnum;
    metadataQuery: string;
    capturePaypal: PaypalProps['capturePayment'];
    createPaypalPayment: (method: OpenapiGetPaymentMethodsRO) => Promise<string>;
    createStripePayment: (method: OpenapiGetPaymentMethodsRO) => void;
    loading: boolean;
    setFailed: (error: string) => void;
    paymentData: OpenapiCreatePaymentRO;
};

export const PaymentMethods: React.FC<Props> = ({
    allPaymentMethods = [],
    currency,
    createPaypalPayment,
    createStripePayment,
    capturePaypal,
    loading,
    metadataQuery,
    setFailed,
    paymentData,
}) => {
    const methods = allPaymentMethods.filter((p) => p.method !== PaymentMethodEnum.CASH);
    const [selected, setSelected] = useState<OpenapiGetPaymentMethodsRO>(methods[0]);

    const { t } = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePaypal = useCallback(() => createPaypalPayment(selected), [selected]);

    if (!selected) return null;

    if (paymentData) {
        return (
            <StripePayment
                setFailed={ setFailed }
                metadataQuery={ metadataQuery }
                currency={ currency }
                paymentData={ paymentData }
            />
        );
    }

    return (
        <Flex direction="column" gap="xl">
            <HorizontalFlex columns={ 3 } gc={ 8 }>
                { methods.map((p) => (
                    <PaymentMethodCell
                        key={ p.method }
                        type={ p.method }
                        onClick={ () => setSelected(p) }
                        selected={ selected.method === p.method }
                    />
                )) }
            </HorizontalFlex>

            <FlexColumns columns={ 1 } gr={ 8 }>
                { selected.method === PaymentMethodEnum.PAYPAL && (
                    <PaypalButton
                        loading={ loading }
                        createPayment={ handlePaypal }
                        capturePayment={ capturePaypal }
                        currency={ currency }
                    />
                ) }

                { selected.method === PaymentMethodEnum.STRIPE && (
                    <Button
                        block={ true }
                        loading={ loading }
                        view="primary"
                        size="s"
                        onClick={ () => createStripePayment(selected) }
                    >
                        <Flex gap="xs" justify="center">
                            { t('Оплатить') }

                            <Amount
                                currency={ currency }
                                weight="bold"
                                type="decimal"
                                currencyOpacity={ false }
                                value={ selected.full.amountWithIncrease }
                            />
                        </Flex>
                    </Button>
                ) }
                <Typography.Text view="caps">
                    { t('Включена наценка за онлайн оплату') }{ ' ' }
                    <Amount
                        type="decimal"
                        currencyOpacity={ false }
                        currency={ currency }
                        value={ selected.full.increase }
                    />
                </Typography.Text>
            </FlexColumns>
        </Flex>
    );
};
