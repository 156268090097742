import React from 'react';
import { FlexColumns } from '@alphakits/ui';

import styles from './index.module.css';

type Props = {
    columns: number;
    gc: number;
};

export const HorizontalFlex: React.FC<Props> = ({ columns, children, gc }) => (
    <FlexColumns className={ styles.component } columns={ columns } gc={ gc }>
        { children }
    </FlexColumns>
);
