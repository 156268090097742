import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusS } from '@alphakits/icons';
import {
    Amount, Box, Button, Flex, FlexColumns, showError, Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { OpenapiOrderRO } from '@escapenavigator/types/dist/openapi/orders/openapi-order.ro';
import { OpenapiUpsellingRO } from '@escapenavigator/types/dist/openapi/upsellings/openapi-upselling.ro';
import { useApi } from 'src/providers/api/context';

type Props = {
    upselling: OpenapiUpsellingRO;
    refetch: () => void;
    order: OpenapiOrderRO;
};

export const Upselling: React.FC<Props> = ({ upselling, refetch, order }) => {
    const { t } = useTranslation();

    const { openapiWidget } = useApi();

    const { addUpsellingToOrderFetch, addUpsellingToOrderLoading } = useApiMethod({
        api: openapiWidget.addUpsellingToOrder,
        successCallback: refetch,
        errorCallback: ({ message }) => showError(message),
    });

    return (
        <Box padding="sm" border={ true } rounded="sm">
            <FlexColumns columns={ 1 } gr={ 12 }>
                <FlexColumns columns={ 1 } gr={ 4 }>
                    <Flex>
                        <Typography.Text tag="div" view="title" weight="bold" color="primary">
                            { upselling.title }
                        </Typography.Text>

                        <Amount
                            value={
                                upselling.byEachPerson && order.players
                                    ? upselling.price * order.players
                                    : upselling.price
                            }
                            view="title"
                            color="primary"
                            type="decimal"
                            weight="bold"
                            currency={ order.currency }
                        />
                    </Flex>

                    <Typography.Text view="caps" color="secondary">
                        { upselling.description }
                    </Typography.Text>
                </FlexColumns>

                <div>
                    <Button
                        view="outlined"
                        leftAddons={ <PlusS /> }
                        size="xs"
                        loading={ addUpsellingToOrderLoading }
                        onClick={ () =>
                            addUpsellingToOrderFetch({
                                upsellingId: upselling.id,
                                orderToken: order.token,
                            }) }
                    >
                        { t('Добавить') }
                    </Button>
                </div>
            </FlexColumns>
        </Box>
    );
};
